<template>
  <v-data-table
    class="mytable"
    :headers="headers"
    :loading="loading"
    :items="filteredPas"
    :search="search"
    multi-sort
    :custom-filter="filterOnlyCapsText"
    :items-per-page.sync="itemsperpage"
    @current-items="getFiltered"
    @click:row="onClickEvent"
    :item-class="itemRowBackground"
  >
    <template v-slot:[`item.img`]="{ item }" class="justify-center">
      <v-img
        v-show="src[item.id]"
        :src="src[item.id]"
        class="grey lighten-2"
        style="width: 50px"
        aspect-ratio="1"
      >
      </v-img>
    </template>
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>一覧</v-toolbar-title>
        <v-btn text @click="downloadCSV">データダウンロード</v-btn
        ><v-switch
          v-if="showRelated"
          v-model="isDb"
          :label="`フィルタ:${isDb.toString()}`"
        ></v-switch>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn
          text
          @click="clearCondition"
          v-show="isCondition"
          class="red--text"
        >
          検索条件クリア
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search_async"
          append-icon="mdi-magnify"
          label="検索"
          single-line
          hide-details
          class="ml-4"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:[`body.append`]>
      <tr>
        <td>
          <v-select
            v-model="modelyear"
            :items="modelyears"
            multiple
            clearable
          ></v-select>
        </td>
        <td>
          <v-select v-model="category" :items="categorise" clearable></v-select>
        </td>
        <td>
          <v-select v-model="maker" :items="makers" clearable></v-select>
        </td>
        <td colspan="4"></td>
        <td>
          <v-select
            v-model="destination"
            :items="destinations"
            clearable
          ></v-select>
        </td>
        <td colspan="6"></td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import Download from "@/mixins/download";
import csv from "csvtojson";
import dayjs from "dayjs";
const protocol_name = "https://cova.sakuraweb.com";
import { taffy } from "taffydb";

var dictionary = null;
import axios from "axios";
import { createNamespacedHelpers } from "vuex";
const { mapGetters: unitsGetters } = createNamespacedHelpers("units");
export default {
  name: "ProductTable",
  mixins: [Download],
  data: () => ({
    myload: true,
    search: "",
    search_async: "",
    search_temp: "",
    loading: false,
    modelyear: null,
    isDb: true,
    category: null,
    maker: null,
    destination: null,
    src: {},
  }),
  props: {
    postPas: {
      type: Array,
    },
    postMydb: {
      type: Function,
    },
    itemsperpage: {
      type: Number,
      default: 10,
    },
    showRelated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelyears: () => {
      const now = dayjs();
      const min = 2006;
      const years = [];
      for (let i = min; i <= now.year() + 2; i++) {
        years.push(i.toString());
      }
      return years;
    },
    categorise: () => [
      "Sport",
      "Scooter",
      "Cruiser",
      "Off/Compe",
      "Dual purpose",
      "Moped",
      "A-2UP Utility",
      "A-Sports",
      "A-Utility",
      "A-Youth",
      "R-Multipurpose",
      "R-Recreation",
      "R-Sports",
      "R-Utility",
      "R-Youth",
    ],
    makers: () => [
      "HONDA",
      "KAWASAKI",
      "SUZUKI",
      "ARCTIC CAT",
      "BAJAJ",
      "BMW",
      "BOMBARDIER",
      "DUCATI",
      "HARLEY DAVIDSON",
      "HERO",
      "JOHN DEERE",
      "KTM",
      "KYMCO",
      "MODENAS",
      "PIAGGIO",
      "POLARIS",
      "SYM",
      "TEXTRON",
      "TRIUMPH",
      "VESPA",
      "VICTORY",
      "YAMAHA",
    ],
    destinations() {
      if (this.showRelated) {
        return ["USA", "ITA", "JPN", "IND", "BRA", "COL", "PHL", "THA", "TWN"];
      }
      return [
        "USA",
        "ITA",
        "JPN",
        "CHN",
        "IND",
        "BRA",
        "COL",
        "DEU",
        "FRA",
        "IDN",
        "MYS",
        "PHL",
        "THA",
        "TWN",
        "VNM",
      ];
    },
    ...unitsGetters({
      unitsDb: "unitsDb",
    }),
    filteredPas() {
      if (!this.isDb || !this.showRelated) {
        return this.postPas;
      }
      return this.postPas.filter((e) => !this.unitsDb[e.id]);
    },
    headers() {
      return [
        {
          text: "モデルイヤー",
          value: "modelyear",
          filter: (value) => {
            if (!this.modelyear) return true;
            if (this.modelyear.length === 0) return true;
            return this.modelyear.indexOf(value) > -1;
          },
        },
        {
          text: "カテゴリ",
          value: "category",
          filter: (value) => {
            if (!this.category) return true;
            return value === this.category;
          },
        },
        {
          text: "メーカー",
          value: "maker",
          filter: (value) => {
            if (!this.maker) return true;
            return value === this.maker;
          },
        },
        { text: "モデル名", value: "modelname" },
        { text: "開発区分", value: "class", filterable: false },
        { text: "開発ランク", value: "rank", filterable: false },
        { text: "排気量", value: "cc" },
        {
          text: "仕向地",
          value: "destination",
          filter: (value) => {
            if (!this.destination) return true;
            return value === this.destination;
          },
        },
        {
          text: "立上年月（発売時期）",
          value: "releasedate",
          filterable: false,
        },
        { text: "Memo", value: "memo" },
        { text: "型式", value: "model", filterable: false },
        { text: "通貨単位", value: "c_type", filterable: false },
        { text: "Image", value: "img", filterable: false },
      ];
    },
    modelcount() {
      if (!this.postMydb) {
        return 0;
      }
      return this.postMydb().distinct("modelname").length;
    },
    isCondition() {
      if (
        this.search ||
        this.modelyear ||
        this.category ||
        this.maker ||
        this.destination
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    postMydb: function () {
      if (!this.myload) {
        return;
      }
      window.setTimeout(() => {
        const con = JSON.parse(window.localStorage.getItem("baseproductcon"));
        this.myload = false;
        if (!con) {
          return;
        }
        this.search = con.search;
        this.search_async = con.search;
        this.modelyear = con.modelyear;
        this.category = con.category;
        this.maker = con.maker;
        this.destination = con.destination;
      }, 500);
    },
    search_async: function (val) {
      this.search_temp = val;
      window.setTimeout(() => {
        if (this.search_temp === this.search_async) {
          this.search = this.search_async;
        }
      }, 500);
    },
  },
  methods: {
    itemRowBackground(item) {
      if (!this.showRelated) {
        return;
      }
      if (this.unitsDb[item.id]) {
        return "db-tr-style-1";
      }
      return;
    },
    async getData(item) {
      if (this.src[item.id]) {
        return;
      }
      if (parseInt(item.id, 10) < 16471) {
        let path = await this.get2018(item);
        this.$set(this.src, item.id, path);
        return;
      }
      if (parseInt(item.id, 10) < 18659) {
        this.$set(this.src, item.id, await this.get2019(item));
        return;
      }
      if (parseInt(item.id, 10) < 19904) {
        this.$set(this.src, item.id, await this.get2020("2020", item));
        return;
      }
      if (parseInt(item.id, 10) < 21672) {
        this.$set(this.src, item.id, await this.get2020("2021", item));
        return;
      }
      if (parseInt(item.id, 10) < 23925) {
        this.$set(this.src, item.id, await this.get2020("2022", item));
        return;
      }
      if (parseInt(item.id, 10) < 25572) {
        this.$set(this.src, item.id, await this.get2020("2023", item));
        return;
      }
      if (parseInt(item.id, 10) < 27969) {
        this.$set(this.src, item.id, await this.get2020("2024", item));
        return;
      }
      this.$set(this.src, item.id, await this.get2020("2025", item));
      return;
    },
    async get2019(item) {
      return protocol_name + "/data/2019/" + item.id + ".jpg";
    },
    async get2020(year, item) {
      let url = "/" + year + "/" + item.maker + "/" + item.destination + "/";
      if (item.link) {
        url += item.link;
      } else {
        url += item.modelname;
      }
      return await this.getFiles(url + "/");
    },
    async get2018(item) {
      if (!dictionary) {
        const data = await axios.get("./data/directory.txt").then();
        let mydata = data.data;
        let json = await csv({ delimiter: "\t" }).fromString(mydata).then();
        dictionary = taffy(JSON.stringify(json));
      }
      const result = [];
      dictionary({ id: item.id }).each((r) => {
        result.push(r);
      });
      if (result.length > 0) {
        return this.getFiles("/" + result[0].path.replace(/\\/g, "/") + "/");
      }
    },
    async getFiles(path) {
      let rtn = "";
      const data = await axios
        .get(protocol_name + "/data/search_img.php?dir=" + encodeURI(path))
        .then();
      Object.keys(data.data).forEach((file) => {
        if (file === "png" || file === "jpg") {
          if (Array.isArray(data.data[file])) {
            rtn = this.calcPath(path, data.data[file][0]);
            return;
          }
          rtn = this.calcPath(path, data.data[file]);
          return;
        }
      });
      // console.log(rtn);
      if (rtn) {
        return rtn;
      }
      return "";
    },
    calcPath(path, filename) {
      return protocol_name + "/data" + path.replace(/%20/g, "%2520") + filename;
    },
    clearCondition() {
      this.search = null;
      this.search_async = null;
      this.modelyear = null;
      this.category = null;
      this.maker = null;
      this.destination = null;
      return false;
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value
          .toString()
          .toLocaleUpperCase()
          .indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
    downloadCSV() {
      this.createCSV(this.postPas, "myresult");
    },
    getFiltered(items) {
      var con = {};
      if (this.myload) {
        return;
      }
      con.search = this.search;
      con.modelyear = this.modelyear;
      con.category = this.category;
      con.maker = this.maker;
      con.destination = this.destination;
      window.localStorage.setItem("baseproductcon", JSON.stringify(con));
      for (let i = 0; i < items.length; i++) {
        this.getData(items[i]);
      }
    },
    onClickEvent(data) {
      this.$emit("click", data);
    },
  },
};
</script>
<style >
.db-tr-style-1 {
  background-color: rgb(215, 215, 44);
}
</style>