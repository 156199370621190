<template>
  <v-container>
    <v-app-bar-nav-icon @click="viewMenu = !viewMenu"></v-app-bar-nav-icon>
    <v-app-bar flat dense class="blue-grey lighten-5">
      <v-toolbar-title class="text-h5">製品写真データ</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon link href="../pdf/help.pdf" target="help">
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-show="!loading">
      <p class="pl-4">
        立上年月が201907以降はスクレイピングによるデータ収集です。
      </p>
      <base-badges :post-pas="pas" :post-mydb="mydb"></base-badges>
      <product-table
        :post-pas="pas"
        :post-mydb="mydb"
        class="mb-2"
      ></product-table>
    </div>
    <base-navi :drawer.sync="viewMenu"></base-navi>
  </v-container>
</template>
<script>
import axios from "axios";
import Processing from "../mixins/processing";
import { taffy } from "taffydb";
import csv from "csvtojson";
import BaseNavi from "@/components/Base/BaseNavi";
import BaseBadges from "@/components/Bases/Badges";
import ProductTable from "@/components/ProductThumb";
export default {
  mixins: [Processing],
  components: {
    BaseBadges,
    ProductTable,
    BaseNavi,
  },
  name: "HelloWorld",
  data: () => ({
    loading: true,
    pas: [],
    mydb: null,
    data: "",
    viewMenu: false,
  }),
  async mounted() {
    document.title = "製品写真 | 他社商計";
    await this.getData();
    this.loadTaffy();
  },
  methods: {
    async getData() {
      this.pas = [];
      const save = window.localStorage.getItem("timestamp");
      if (save) {
        if (new Date().getTime() - 3600000 < save) {
          this.data = window.localStorage.getItem("data");
          return;
        }
      }
      await this.fetchData();
    },
    async fetchData() {
      const data = await axios
        .get("https://cova.sakuraweb.com/data/data.php")
        .then();
      this.data = data.data;
      window.localStorage.setItem("data", this.data);
      window.localStorage.setItem("timestamp", new Date().getTime());
    },
    loadTaffy() {
      csv({ delimiter: "\t" })
        .fromString(this.data)
        .then((csv) => {
          this.loading = false;
          this.pas = csv;
          this.mydb = taffy(JSON.stringify(csv));
        });
    },
  },
};
</script>
